<template>
  <v-container fluid class="mt-12 py-0" v-if="render">
    <v-row class="home__row-contact-home">
      <v-col cols="12" md="6" class="background-blue-light pa-0">
        <template v-if="contactUsSingleton.contact_image != null">
          <v-img
            class="contact_image"
            :src="
              'https://www.cymcms.actstudio.xyz/' +
              contactUsSingleton.contact_image.path
            "
          ></v-img>
        </template>
      </v-col>

      <v-col cols="12" md="6" class="background-blue py-10 py-sm-3">
        <v-row justify="center" align="center" class="fill-height">
          <v-col cols="auto" class="text-center">
            <div class="div-center-contact">
              <p class="mt-sm-4 white--text continuos-texts-2">
                {{ contactUsSingleton.contact_card.contact_description }}
              </p>
              <v-btn
                color="#FFC979"
                elevation="0"
                rounded
                class="home__content--btn btn-general mt-2 mt-sm-5"
                :ripple="false"
                :to="contactUsSingleton.contact_card.contact_btn_link"
              >
                <span class="home__content--btn__span continuos-texts-2 ">
                  {{ contactUsSingleton.contact_card.contact_btn_text }}
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ContactUs",
  data() {
    return {
      render: false,
      contactUsSingleton: {},
    };
  },
  beforeCreate() {
    //fetch para el contacto global
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/contactComponent?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.contactUsSingleton = data))
      .then(data => (this.render = true));
  },
};
</script>