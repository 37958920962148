<template>
  <div class="Header">
    <!-- #ECE8DD -->
    <v-app-bar
      app
      light
      fixed
      elevation="0"
      class="header-app"
      @scroll="changeLogo"
      :color="colorAppBar"
      :height="heigthAppBar"
    >
      <!-- tablet hacia abajo -->
      <div class="d-md-none align-center" style="z-index: 6">
        <router-link to="/">
          <v-img
            alt="CYM logo"
            class="header-app--logo"
            contain
            :src="logo2"
            transition="scale-transition"
          >
          </v-img>
        </router-link>
      </div>
      <!-- tablet hacia abajo -->

      <!-- desk -->
      <div class="d-none d-md-flex align-center">
        <template v-if="varchangeLogo == false">
          <router-link to="/">
            <v-img
              alt="CYM logo"
              class="shrink header-app--logo"
              contain
              :src="updateThings == 0 ? logo1 : logo3"
              transition="scale-transition"
            >
            </v-img>
          </router-link>
        </template>

        <template v-else>
          <router-link to="/">
            <v-img
              alt="CYM logo"
              class="shrink header-app--logo-2"
              contain
              :src="logo2"
              transition="scale-transition"
            >
            </v-img>
          </router-link>
        </template>
      </div>
      <!-- desk -->

      <v-spacer></v-spacer>

      <!-- items nav escritorio -->
      <div class="d-none d-md-block">
        <router-link
          v-bind:to="item.url"
          v-for="(item, i) in navigationItems"
          v-bind:key="i"
          class="not-text-decoration header-app__items"
        >
          <span class="header-app__items--span">{{ item.name }}</span>
        </router-link>
      </div>
      <!-- /items nav escritorio -->

      <!-- items nav escritorio -->
      <div class="d-md-none">
        <v-img
          alt="Menú hamburguesa"
          class="shrink"
          contain
          src="@/assets/icons/menu-mobile.svg"
          transition="scale-transition"
          @click="isOpen = !isOpen"
        >
        </v-img>

        <div id="menu" v-bind:class="{ menuOpen: isOpen }" class="d-md-none">
          <v-container class="">
            <v-row class="pt-0 pb-10">
              <v-col
                cols="12"
                v-for="(link, lk2) in navigationItems"
                :key="lk2"
                class="text-center pt-3"
                @click="isOpen = !isOpen"
              >
                <router-link
                  v-bind:to="link.url"
                  class="not-text-decoration d-block"
                >
                  <span class="d-block">{{ link.name }}</span>
                </router-link>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center" class="pt-6">
              <v-col cols="7">
                

                <img
                  src="@/assets/icons/placeholder.svg"
                  width="20"
                  class="d-inline-block vertical-aligment pb-2 pb-sm-0"
                />

                
                <span class="d-inline-block pl-4 mt-3 continuos-texts-small"
                  >Futuro Nogalar 111, <br />
                  San Nicolás de los
                  <br class="d-none d-sm-block d-md-none" />
                  Garza,
                  <br class="d-md-block d-lg-none" />N.L., 66484
                </span>

                <div class="d-block"></div>
                <img
                  src="@/assets/icons/call.svg"
                  width="20"
                  class="d-inline-block mt-3"
                />
                <span
                  class="d-inline-block pl-4 continuos-texts-small vertical-aligment"
                  >011(52)(81)83059000
                </span>

                <div class="d-block"></div>
                <img
                  src="@/assets/icons/email.svg"
                  width="20"
                  class="d-inline-block mt-3"
                />
                <span
                  class="d-inline-block pl-4 continuos-texts-small vertical-aligment"
                  >sales@cymm.com</span
                >
              </v-col>
            </v-row>
            
          </v-container>
        </div>
      </div>

      <!-- /items nav escritorio -->

      <v-spacer class="d-none d-md-block"></v-spacer>
    </v-app-bar>
  </div>
</template>
<script>
import logo1 from "@/assets/icons/cym-icon.svg";
import logo2 from "@/assets/icons/cym-icon-2.svg";
import logo3 from "@/assets/icons/cym-icon-white.svg";

export default {
  name: "Header",
  props: {
    updateThings: { type: Number, required: true },
  },
  data() {
    return {
      logo1,
      logo2,
      logo3,
      isOpen: false,
      varchangeLogo: false,
      heigthAppBar: 53,
      colorAppBar: "#ECE8DD",
      navigationItems: [
        {
          name: "Home",
          url: "/",
        },
        {
          name: "About us",
          url: "/about",
        },
        {
          name: "Products",
          url: "/products",
        },
        {
          name: "Showrooms",
          url: "/virtual-showroom",
        },
        {
          name: "Blog",
          url: "/blog",
        },
        {
          name: "Contact",
          url: "/contact",
        },
      ],
    };
  },
  mounted() {
    //event onResize
    window.addEventListener("resize", this.onResize, { passive: true });

    //event scroll
    window.addEventListener("scroll", this.changeLogo);
  },
  beforeDestroy() {
    //event onResize
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize: function (e) {
      //alto de la barra de navegación
      if (window.innerWidth <= 599) this.heigthAppBar = 53;
      else if (window.innerWidth >= 600 && window.innerWidth <= 959)
        this.heigthAppBar = 40;
      else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heigthAppBar = 53;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.heigthAppBar = 53;
      else if (window.innerWidth >= 1904) this.heigthAppBar = 53;

      if (window.innerWidth <= 599) this.colorAppBar = "#ECE8DD";
      else this.colorAppBar = "#ECE8DD";
    },
    changeLogo: function (e) {
      if (document.documentElement.scrollTop > 150) {
        this.varchangeLogo = true;
      } else {
        this.varchangeLogo = false;
      }

      if (window.innerWidth <= 599) {
        if (document.documentElement.scrollTop > 150) {
          this.colorAppBar = "#ECE8DD";
        } else {
          this.colorAppBar = "#ECE8DD";
        }
      }
    },
  },
};
</script>