<template>
  <div class="Footer">
    <v-footer class="footer" color="#ECE8DD">

      <v-row justify="center">
        <v-col cols="11" sm="10">
          <v-row align="center">
            <v-col cols="12" sm="6" class="border-right-column-only">
              <v-row align-sm="center" justify="center" justify-sm="space-around">
                <v-col cols="auto" sm="auto">
                  <v-img
                    src="@/assets/icons/cym-icon.svg"
                    class="footer__logo"
                  ></v-img>
                </v-col>

                <v-col cols="8" sm="auto" class=" pl-6 pl-sm-3">
                  <div class="footer__max-width-about">
                    <img src="@/assets/icons/placeholder.svg" width="20" class="d-inline-block  pb-2 pb-sm-3"/>
                    <a href="https://goo.gl/maps/7RFeh77d8XNhWMs76" target="_blank" class="not-text-decoration">


                      <span class="d-inline-block pl-4 mt-3 continuos-texts-small "
                        >Futuro Nogalar 111, <br>
                        San Nicolás de los 
                        <br class="d-none d-sm-block d-md-none"> 
                        Garza,
                        
                        <!-- <br class=" d-md-block d-lg-none">N.L.,  66484 -->
                        <br class=" ">N.L.,  66484
                      </span>
                    </a>

                    <div class="d-block"></div>
                    <img src="@/assets/icons/call.svg" width="20" class="d-inline-block  mt-3"/>
                    <a class="not-text-decoration" href="tel:011(52)(81)83059000">

                    <span class="d-inline-block pl-4  continuos-texts-small vertical-aligment"
                      >011(52)(81)83059000
                    </span>
                    </a>

                    <div class="d-block"></div>
                    <img src="@/assets/icons/email.svg" width="20" class="d-inline-block mt-3"/>
                       <a class="not-text-decoration" href="mailto:sales@cymm.com">

                    <span class="d-inline-block pl-4  continuos-texts-small vertical-aligment">
                      sales@cymm.com
                       </span>
                       </a>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- row sm para arriba -->
              <v-row justify="space-around" class="d-none d-sm-flex">
                <v-col cols="auto">
                  <template v-for="(link, lkd) in navItemsFooter">
                    <router-link
                      v-bind:key="lkd"
                      v-bind:to="link.url"
                      v-bind:class="{ 'mt-3': lkd != 0 }"
                      v-if="lkd <= 2"
                      class="not-text-decoration d-block"
                    >
                      <span>{{ link.text }}</span>
                    </router-link>
                  </template>
                  
                </v-col>
                <v-col cols="auto">
                  <template v-for="(link, lkd2) in navItemsFooter">
                    <router-link
                      v-bind:key="lkd2 + '-2'"
                      v-bind:to="link.url"
                      v-bind:class="{ 'mt-3': lkd2 != 3 }"
                      v-if="lkd2 >= 3"
                      class="not-text-decoration d-block"
                    >
                      <span :class="{'continuos-texts-small' :link.url==='/privacy-policy'}">{{ link.text }}</span>
                    </router-link>
                  </template>
                </v-col>
                <v-col cols="8" xl="9" class="px-0 pl-xl-14">
                  <router-link
                      to="/"
                      class="not-text-decoration d-block"
                    >
                      <span class="continuos-texts-small">Camas y Muebles Monterrey SA de CV</span>
                    </router-link>

                </v-col>
                

              </v-row>
              
              <!-- /row sm para arriba -->

              <v-row class="d-none">
                <v-col
                  cols="4"
                  v-for="(link, lk1) in navItemsFooter"
                  v-bind:key="lk1+'-1'"
                  v-show="lk1 <= 3"
                >
                  <router-link
                    v-bind:to="link.url"
                    class="not-text-decoration d-block"
                    
                  >
                    <span >{{ link.text }}</span>
                  </router-link>
                </v-col>

                <v-col
                  cols="auto"
                  v-for="(link, lk2) in navItemsFooter"
                  v-bind:key="lk2"
                  v-show="lk2 == 4"
                >
                  <router-link
                    v-bind:to="link.url"
                    class="not-text-decoration d-block"
                  >
                    <span>{{ link.text }}</span>
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navItemsFooter: [

        {
          text: "Home",
          url: "/",
        },
        {
          text: "About us",
          url: "/about-us",
        },
        {
          text: "Products",
          url: "/products",
        },
        {
          text: "Showroom",
          url: "/virtual-showroom",
        },
        {
          text: "Blog",
          url: "/blog",
        },
        {
          text: "Contact",
          url: "/contact",
        },
        
      ],
    };
  },
};
</script>