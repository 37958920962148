<template>
  <v-app>
    <!-- menú -->
    <Header :updateThings="change"></Header>
    <!-- /menú -->

    <!-- views -->
    <v-main>
      <transition name="fade-transition">
        <router-view @update="changeThings"></router-view>
      </transition>
    </v-main>
    <!-- /views -->

    <!-- footer -->
    <Footer></Footer>
    <!-- /footer -->
  </v-app>
</template>

<script>
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({
    change: 0
  }),
  methods:{
    changeThings: function(x){
      this.change = x;
    }
  }
};
</script>
